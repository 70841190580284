import sampleConfig from "@/config";
import { API } from 'aws-amplify';
import Axios from 'axios';

// const FILE_CHUNK_SIZE = 268435456; // change to file['upload']['chunkSize']
export default {
  getSignedURL(file, username, idToken) {
    const apiName = sampleConfig.api.name;
    const path = sampleConfig.api.path + '/' + username;
    let myInit = {
      headers: {
        Authorization: idToken,
      },
      body: {
        filePath: file.name,
        contentType: file.type
      }
    }
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res['url'] || '/')
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject('/')
      });
  },
  getUploadId(file, username, idToken) {
    const apiName = sampleConfig.api.name;
    const path = sampleConfig.api.multi_upload_path + '/' + username;
    let myInit = {
      headers: {
        Authorization: idToken,
      },
      body: {
        filePath: file.name,
        contentType: file.type
      }
    }
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res['response']['UploadId']);
      })
      .catch((err) => {
        console.error("Error! ", err);
        return err;
      });
  },
  async getPartSignedURL(file, username, idToken, partNumber) {
    const apiName = sampleConfig.api.name;
    const path = sampleConfig.api.multi_upload_part_path + '/' + username;
    let myInit = {
      headers: {
        Authorization: idToken,
      },
      body: {
        fileName: file.name,
        uploadId: file.uploadId,
        partNumber: partNumber
      }
    };
    const response = await API.post(apiName, path, myInit);
    // console.log("API call response", response);
    const url = response['url'];
    return url;
  },
  async getURLandUpload(file, vm) {
    const parts = file['upload']['totalChunkCount'];
    const axios = Axios.create();
    delete axios.defaults.headers.put['Content-Type']
    const resParts = [];
    for (let index = 0; index < parts; index++) {
        const url = await this.getPartSignedURL(file, vm.idToken.claims.preferred_username, vm.$auth.getIdToken(), index+1);
        // console.log("url", url);
        const start = index * vm.dropzone.options.chunkSize
        const end = (index + 1) * vm.dropzone.options.chunkSize
        const blob = index < parts
            ? file.slice(start, end)
            : file.slice(start)
        const response = await axios.put(url, blob);
        resParts.push(response);
        // console.log("PUT uploadPart response: ", response);
        file.upload.progress = 100 * ((index + 1) / parts);
        file.upload.bytesSent = end;
        vm.dropzone.emit(
          "uploadprogress",
          file,
          file.upload.progress,
          file.upload.bytesSent
        );
    }
    return resParts.map((part, index) => ({
      ETag: (part).headers.etag,
      PartNumber: index + 1
    }));
  },
  async completeMultiUpload(file, vm) {
    const apiName = sampleConfig.api.name;
    const path = sampleConfig.api.complete_multi_upload_path + '/' + vm.idToken.claims.preferred_username;
    let myInit = {
      headers: {
        Authorization: vm.$auth.getIdToken(),
      },
      body: {
        Key: file.name,
        MultipartUpload: { Parts: file.parts },
        UploadId: file.uploadId
      }
    };
    return API.post(apiName, path, myInit)
    .then((res) => {
        return Promise.resolve(res['response']);
    })
    .catch((err) => {
        console.error(err)
        return Promise.reject('error')
    });
  }
}
