<template>
  <header class="navbar navbar-expand-lg navbar-end navbar-sticky-top navbar-dark bg-dark navbar-shadow pt-0 d-print-none" style="position: sticky;">
    <div class="container">
      <nav class="js-mega-menu navbar-nav-wrap">
        <!-- Logo -->
        <a class="navbar-brand" href="/" aria-label="Front">
          <img class="navbar-brand-logo" src="../static/svg/ailogowhite.svg" alt="Logo">
        </a>
        <!-- Toggle -->
        <button type="button" class="navbar-toggler ms-auto" data-bs-toggle="collapse" data-bs-target="#navbarNavMenuWithMegaMenu" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarNavMenuWithMegaMenu">
          <span class="navbar-toggler-default">
            <i class="bi-list"></i>
          </span>
          <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
          </span>
        </button>
        <!-- End Toggle -->
        <div class="collapse navbar-collapse" id="navbarNavMenuWithMegaMenu">
          <!-- Navbar -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link active" to="/" v-if="authState && authState.isAuthenticated">Home</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-item" to="/profile">OIDC Profile</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-item" to="/aws">AWS</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-item" to="/dropzone">Dropzone</router-link>
            </li> -->
            <!-- Dropdown -->
            <li class="hs-has-sub-menu nav-item">
            <!-- <li class="nav-item dropdown"> -->
              <a class="hs-mega-menu-invoker nav-link dropdown-toggle" href="#" id="headerWithMegaMenuDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Account</a>

              <div class="hs-sub-menu dropdown-menu" aria-labelledby="headerWithMegaMenuDropdown" style="min-width: 230px;">
                <!-- <a class="dropdown-item" href="#">Action TBD</a>
                <div class="dropdown-divider"></div> -->
                <a class="dropdown-item text-dark" v-if="authState && authState.isAuthenticated" @click="logout">Logout</a>
                <a class="dropdown-item text-dark" v-else @click="login">Login</a>
                <!-- <a class="dropdown-item" href="#">
                  <i class="bi-box-arrow-right"></i>
                </a> -->
              </div>
            </li>
            <!-- End Dropdown -->
          </ul>
        <!-- End Navbar -->
        </div>
      </nav>
    </div>
  </header>
  <Footer style="position:absolute; bottom:0; width:100%;"></Footer>
</template>

<script>
import HSMegaMenu from "@/static/vendor/hs-mega-menu/dist/hs-mega-menu.min.js";
import Footer from '@/components/Footer.vue';

export default ({
  name: 'home',
  components: {
    Footer
  },
  methods: {
    async login () {
      await this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout () {
      await this.$auth.signOut()
    }
  },
  mounted() {
    (function() {
    // INITIALIZATION OF MEGA MENU
    // =======================================================
      new HSMegaMenu('.js-mega-menu', {
        desktop: {
          position: 'left'
        }
      })
    })();
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../static/vendor/hs-mega-menu/dist/hs-mega-menu.min.css';
@import '../static/vendor/bootstrap-icons/font/bootstrap-icons.css';
@import '../static/vendor/aos/dist/aos.css';
@import '../static/css/theme.min.css';
@import '../static/css/snippets.min.css';

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
