<template>
    <footer class="bg-dark">
        <div class="container text-left content-space-1">
            <!-- Logo -->
            <a class="d-inline-flex align-items-center mb-2" href="/" aria-label="Front">
                <img class="brand" src="../static/svg/ailogowhite.svg" alt="Logo">
            </a>
            <!-- End Logo -->
            <p class="small text-muted mb-0">© 2022 Applied Insight, LLC. All rights reserved.</p>
        </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>