import { createApp } from 'vue'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import App from './App.vue'
import router from './router'
import AWS from 'aws-sdk'

import sampleConfig from '@/config'

const oktaAuth = new OktaAuth(sampleConfig.oidc)

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: sampleConfig.cognito.identityPoolId,
    Logins: {
        [sampleConfig.cognito.domain]: 'DUMMY_OIDC_TOKEN'
    }
 },{
    region: sampleConfig.cognito.region
 });

const app = createApp(App)
app.config.globalProperties.$AWS = AWS

app.use(router)
   .use(OktaVue, { oktaAuth })
   .mount('#app')