<template>
  <div >
    <div style="text-align: left; color: white; background-color: black; padding: 5px;  overflow-wrap: break-word">
    <code > <pre>
export AWS_ACCESS_KEY_ID={{credentials.accessKeyId}}
export AWS_SECRET_ACCESS_KEY={{credentials.secretAccessKey}}
export AWS_SESSION_TOKEN={{credentials.sessionToken}}
</pre>
    </code>
    </div>
    <div>exp => {{credentials.expireTime}}</div>
    <button @click="request">Make API Request</button>
    <div>Response => {{response}}</div>
  </div>
</template>

<script>
import AWS from 'aws-sdk'
import sampleConfig from '@/config'
import axios from "axios";
import { aws4Interceptor } from "aws4-axios";

export default {
  data () {
    return {
      credentials: {},
      response: "",
    }
  },
  async created () {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: sampleConfig.cognito.identityPoolId,
        Logins: {
            [sampleConfig.cognito.domain]: this.$auth.getIdToken()
        }
    },{
        region: sampleConfig.cognito.region
    });

    try {
      if (AWS.config.credentials.needsRefresh()){
        await AWS.config.credentials.refreshPromise()
      }
      this.credentials = AWS.config.credentials
    } catch (e) {
      console.error(`Errors! ${e}`)
    }
  },
  methods: {
    request() {
      const client = axios.create();

      const interceptor = aws4Interceptor({
        region: sampleConfig.api.region,
        service: "execute-api",
      },{
          accessKeyId: this.credentials.accessKeyId,
          secretAccessKey: this.credentials.secretAccessKey,
          sessionToken: this.credentials.sessionToken
      });

      client.interceptors.request.use(interceptor);

      client.post("https://" + sampleConfig.api.hostname + sampleConfig.api.path, {filePath: "test.txt"}).then((res) => {
        this.response = res.data
        console.log(res)
      });
    }
  }
}
</script>