<template>
<div id="container">
  <HeaderMenu></HeaderMenu>
  <router-view/>
</div>
</template>

<script>
// import Home from '@/views/Home.vue';
import HeaderMenu from '@/components/HeaderMenu.vue'
// import '@/static/vendor/hs-go-to/dist/hs-go-to.min.js'
// import '@/static/vendor/prism/prism.js'
// import '@/static/vendor/hs-show-animation/dist/hs-show-animation.min.js'

export default {
  name: 'HomePage',
  components: {
    HeaderMenu
  }
}

</script>


<style>
  @import './static/vendor/hs-mega-menu/dist/hs-mega-menu.min.css';
  @import './static/vendor/bootstrap-icons/font/bootstrap-icons.css';
  @import './static/vendor/aos/dist/aos.css';
  @import './static/css/theme.min.css';
  @import './static/css/snippets.min.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}*/

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

