export default {
    oidc: {
        clientId: '0oa410gicaBH1JPGL1d7',
        issuer: 'https://applied-insight.oktapreview.com',
        redirectUri: 'https://transfer-test2.dev.ai-products.cloud/login/callback',
        scopes: ['openid', 'profile', 'email']
    },
    cognito: {
        region: 'us-east-1',
        identityPoolId: 'us-east-1:620c878f-0cda-4d21-8f96-6efdc21eb3ce',
        domain: 'applied-insight.oktapreview.com'
    },
    api: {
        name: 'file-upload-api-test2',
        region: 'us-east-1',
        hostname: 'sdjh6z020h.execute-api.us-east-1.amazonaws.com',
        path: '/upload',
        multi_upload_path: '/create-multipart-upload',
        multi_upload_part_path: '/upload-part',
        complete_multi_upload_path: '/complete-multipart-upload'
    },
    amplify_config: {
        cookie_domain: 'transfer-test2.dev.ai-products.cloud',
        cookie_exp_days: 1
    }
}