<template>
  <div v-if="authState && authState.isAuthenticated" class="home">
    <DropzoneComponent></DropzoneComponent>
  </div>
  <div v-else>
    <!-- Full Page Image Header with Vertically Centered Content -->
    <header class="masthead">
      <div class="container h-100">
        <div class="row h-75 align-items-center">
          <div class="col-12 text-center">
            <h1 class="fw-light">Altitude File Transfer Service</h1>
            <p class="lead">A one-way transfer of files between networks</p>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
// @ is an alias to /src
import DropzoneComponent from '@/components/Dropzone.vue'
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import sampleConfig from '@/config'

Amplify.configure({
  Auth: {
    identityPoolId: sampleConfig.cognito.identityPoolId,
    region: sampleConfig.cognito.region,
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: sampleConfig.amplify_config.cookie_domain,
    // OPTIONAL - Cookie path
      path: '/',
    // OPTIONAL - Cookie expiration in days
      expires: sampleConfig.amplify_config.cookie_exp_days,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },
  },
  API: {
    endpoints: [
      {
        name: sampleConfig.api.name,
        endpoint: "https://" + sampleConfig.api.hostname,
        region: sampleConfig.api.region
      }
    ]
    }
});
// function setCookie(c_name, value, expiredays) {  
//   var exdate = new Date();  
//   if (expiredays == null) {
//     exdate.setDate(exdate.getDate() + sampleConfig.file_transfer_app.cookie_exp_days);
//   } else {
//     exdate.setDate(exdate.getDate() + expiredays); 
//   } 
//   document.cookie = c_name + "=" + value + ";path=/" + ";expires=" + exdate.toGMTString() + ";secure=true;domain=" + sampleConfig.amplify_config.cookie_domain;
// }
export default {
  name: 'Home',
  components: {
    DropzoneComponent
  },
  async created() {
    const idTokenObj = await this.$auth.tokenManager.get('idToken')
    const name = idTokenObj.claims['name']
    const email = idTokenObj.claims['email']
    const pref_username = idTokenObj.claims['preferred_username']
    Auth.federatedSignIn(
      sampleConfig.cognito.domain,
      {
          token: this.$auth.getIdToken(),
          expires_at: idTokenObj.claims['exp']
      },
      {
          name,
          email,
          pref_username
      }
    )
    // .then(cred => {
    //   for(var k in cred){
    //     //Return the value for the current key
    //     var v = cred[k];
    //     console.log('credential: ', v);
    //     // setCookie(k, v);
    //   }
      // console.log(cred);
    // })
    .catch(e => {
      console.log(e)
    });
    // Auth.currentAuthenticatedUser().then(user => {
    //   for(var k in user){
    //     //Return the value for the current key
    //     var v = user[k];
    //     // setCookie(k, v);
    //     console.log(v)
    //   }
    //   console.log(user);
    //   // console.log(user);
    // });
  }
}
</script>
<style>
  @import '../static/css/theme.min.css';
  @import '../static/css/snippets.min.css';

  .masthead {
    height: 95vh;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>