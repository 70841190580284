<template>
  <form class="dropzone js-dropzone m-3 col-auto dz-dropzone">
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div class="dz-message" id="dropzone-message" >
      <span class="dropzone-title">Drop files here or click to select</span><br/>
      <span class="dropzone-info">You can upload multiple files at once</span>
    </div>
  </form>
</template>

<script>
import Dropzone from "dropzone";
import "../../node_modules/dropzone/dist/dropzone.css";
import lambda from "../lambda";
import AWS from "aws-sdk";
import sampleConfig from "@/config";

Dropzone.autoDiscover = false;

export default {
  name: "dropzone",
  data() {
    return {
      credentials: {},
      idToken: {}
    };
  },
  async created() {
    this.idToken = await this.$auth.tokenManager.get('idToken')

    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: sampleConfig.cognito.identityPoolId,
        Logins: {
          [sampleConfig.cognito.domain]: this.$auth.getIdToken(),
        },
      },
      {
        region: sampleConfig.cognito.region,
      }
    );

    try {
      if (AWS.config.credentials.needsRefresh()) {
        await AWS.config.credentials.refreshPromise();
      }
      this.credentials = AWS.config.credentials;
    } catch (e) {
      console.error(`Errors! ${e}`);
    }
  },
  mounted() {
    const vm = this;

    let options = {
      // The URL will be changed for each new file being processing
      url: "/",

      // Since we're going to do a `PUT` upload to S3 directly
      method: "put",

      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        // If multipart uplaod, we send file to s3 manually on "processing" 
          // so override xhr.send to do nothing.
        if (file.size > (5 * 1024 * 1024)) {  
          xhr.send = () => {};
        } else { // let dropzone upload file to s3
          let _send = xhr.send;
          xhr.send = () => {
            _send.call(xhr, file);
          };
        }
      },

      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,
      addRemoveLinks: true,

      // Enable chunking for large files
      chunking: true,
      forceChunking: true,
      chunkSize: 268435456,
      params: function params(files, xhr, chunk) {
        if (chunk) {
          return {
            dzuuid: chunk.file.upload.uuid,
            dzchunkindex: chunk.index,
            dztotalfilesize: chunk.file.size,
            dzchunksize: this.options.chunkSize,
            dztotalchunkcount: chunk.file.upload.totalChunkCount,
            dzchunkbyteoffset: chunk.index * this.options.chunkSize
          };
        }
      },

      // maxFileSize (in MiB): set to 10GB
        // TODO: Change this to 5TB (5242880 MiB) when multipart upload option is implemented.
      maxFilesize: 102400,
      maxFiles: 10,

      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      headers: {},

      // Customize the wording
      dictDefaultMessage: document.querySelector("#dropzone-message").innerHTML,

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      // Here we request a signed upload URL when a file being accepted
      accept(file, done) {
        // Patch to allow us to limit the max file count before the uploaad process initiates
        // Source: https://github.com/dropzone/dropzone/blob/6249bfc1af493aba43fe4b7198f9571b2d3d9c24/src/dropzone.js#L753
        if (vm.dropzone.files.length > vm.dropzone.options.maxFiles) {
          done(
            vm.dropzone.options.dictMaxFilesExceeded.replace(
              "{{maxFiles}}",
              vm.dropzone.options.maxFiles
            )
          );
        }
        else if (file.size > (5 * 1024 * 1024)) {
          done();
          // Manually process each file
          setTimeout(() => vm.dropzone.processFile(file));
        }    
        else {
          lambda.getSignedURL(file, vm.idToken.claims.preferred_username, vm.idToken.idToken)
          .then((url) => {
            file.uploadURL = url;
            done();
            // Manually process each file
            setTimeout(() => vm.dropzone.processFile(file));
          }).catch((err) => {
            done("Failed to get an S3 signed upload URL", err);
          });
        }
      },
    };

    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$el, options);

    // Set signed upload URL for each file
    vm.dropzone.on("processing", (file) => {
      if (file.size > (5 * 1024 * 1024)) { // send file to S3 via multipart upload
        lambda.getUploadId(file, vm.idToken.claims.preferred_username, vm.$auth.getIdToken())
        .then((uploadId) => {
          file.uploadId = uploadId;
          lambda.getURLandUpload(file, vm)
          .then((partsMap) => {
            file.parts = partsMap;
            lambda.completeMultiUpload(file, vm)
            .then(() => {
                // set file status to success
                file.status = Dropzone.SUCCESS;
                vm.dropzone.emit("success", file);
                vm.dropzone.emit("complete", file);
            }).catch((err) => { // completeMultipartUpload error:
                // set file status to error
                file.status = Dropzone.ERROR;
                vm.dropzone.emit("error", file, "Could not upload file", err);
                vm.dropzone.emit("complete", file);
            //   console.log("completeMultipartUpload Error: ", err);
            });
          }).catch((err) => { // uploadParts error:
          // set file status to error
          file.status = Dropzone.ERROR;
          vm.dropzone.emit("error", file, "Could not upload file", err);
          vm.dropzone.emit("complete", file);
          // console.log("Multipart Upload (UploadParts) Error:", err);
          });
        }).catch((err) => {  // getUpoadId error:
        // set file status to error
        file.status = Dropzone.ERROR;
        vm.dropzone.emit("error", file, "Could not upload file", err);
        vm.dropzone.emit("complete", file);
        // console.log("Multipart Upload (getUploadId) Error: ", err);
        })
      } else {  // let dropzone handle S3 upload  
        vm.dropzone.options.url = file.uploadURL;
        // TODO: Move this to a more appropriate location once you find out more about how Vue works
        vm.dropzone.options.headers = {'X-Amz-Tagging': `uploading_user=${vm.idToken.claims.preferred_username}&sub=${vm.idToken.claims.sub}`};
      }
    });
  },
};
</script>
