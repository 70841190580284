import { LoginCallback, navigationGuard } from '@okta/okta-vue'
import { createRouter, createWebHistory } from 'vue-router'
import ProfileComponent from '@/components/Profile'
import AWSComponent from '@/components/AWS'
import Home from '../views/Home.vue'
import DropzoneComponent from '@/components/Dropzone'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: '/profile',
    component: ProfileComponent,
  },
  {
    path: '/aws',
    component: AWSComponent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dropzone',
    component: DropzoneComponent,
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(navigationGuard)
export default router
